var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('FormModal', {
    key: "ModalTugBoat".concat(_vm.keyModalTugBoat),
    attrs: {
      "uri": "/tug_boat",
      "title": "Data Master Tug Boat",
      "is-modal-active": _vm.dataModalTugBoat,
      "payload": _vm.dataPayloadModalTugBoat,
      "column": _vm.columnModalTugBoat,
      "action": _vm.operationModalTugBoat,
      "data-i-d": "".concat(_vm.dataIDModalTugBoat),
      "resolve-fetch-detail": _vm.methodModalTugBoatResolveFetchDetail
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.dataModalTugBoat = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.dataModalTugBoat = $event;
      },
      "closeModal": _vm.methodModalTugBoat
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_vm.$store.state.app.roleName !== 'viewer' && !_vm.disableCreateData ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'success'
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Tambah Data ")], 1)])]) : _vm._e()], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "is-loading": _vm.yo_isLoading,
      "total-rows": _vm.total_records,
      "columns": _vm.$store.state.app.roleName === 'viewer' || _vm.$store.state.app.roleName === 'jetty man' ? _vm.columns.filter(function (item) {
        return item.field !== 'action';
      }) : _vm.columns,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      },
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-header-row",
      fn: function fn(props) {
        return [_vm._v(" Kegiatan "), _c('BBadge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('strong', [_vm._v(_vm._s(props.row.label))])]), _c('BBadge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" Jumlah Data : " + _vm._s(props.row.children.length) + " ")])];
      }
    }, {
      key: "table-row",
      fn: function fn(props) {
        var _props$row$user2, _props$row$user2$abil;
        return [props.column.field === 'pip_no_pbmbb' ? _c('span', [_c('strong', [_vm._v(" " + _vm._s(props.row.pip_no_pbmbb) + " "), _c('br'), _c('span', {
          domProps: {
            "textContent": _vm._s("".concat(props.row.kegiatan_hm.keg_name === 'BONGKAR' ? 'Asal: ' : 'Tujuan: ').concat(props.row.pip_address))
          }
        })])]) : props.column.field === 'ftb.kapal' ? _c('span', [_c('strong', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.tug_boat.name_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.tug_boat.name_tug_boat) + " ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Nomor PKK")])]), _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.tug_boat.no_pkk_tug_boat);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.tug_boat.no_pkk_tug_boat) + " ")])], 1), _c('hr')]), _vm._v(" First Created BY "), _c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props.row.source))])], 1) : props.column.field === 'ftb.keterangan' ? _c('span', [_c('strong', [_c('span', [_vm._v(" ")]), _c('div', {
          staticClass: "divider"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_vm._v("Rencana Sandar")])]), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "href": "#",
            "variant": 'light-primary'
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.dateHumanReadAble(props.row.rencana_sandar));
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.rencana_sandar)) + " "), _c('feather-icon', {
          staticClass: "cursor-pointer mr-1",
          attrs: {
            "icon": _vm.dateHumanReadAble(props.row.rencana_sandar).includes('AM') ? 'SunIcon' : 'MoonIcon'
          }
        })], 1), _c('hr'), _c('h5', [_vm._v(" Direncanakan oleh "), _c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_c('strong', {
          on: {
            "click": function click($event) {
              var _props$row$user, _props$row$user$abili;
              return _vm.copyText((_props$row$user = props.row.user) === null || _props$row$user === void 0 ? void 0 : (_props$row$user$abili = _props$row$user.abilities[0]) === null || _props$row$user$abili === void 0 ? void 0 : _props$row$user$abili.action);
            }
          }
        }, [_vm._v(_vm._s((_props$row$user2 = props.row.user) === null || _props$row$user2 === void 0 ? void 0 : (_props$row$user2$abil = _props$row$user2.abilities[0]) === null || _props$row$user2$abil === void 0 ? void 0 : _props$row$user2$abil.action))])])], 1)], 1)]) : props.column.field === 'ftb.status' ? _c('span', [_c('h6', [_c('b-row', [_c('b-col', [_c('strong', [_c('b-badge', {
          staticClass: "d-block py-1",
          attrs: {
            "pill": "",
            "variant": _vm.formStatusVariant(_vm.ftbStatus(props.row))
          },
          on: {
            "click": function click($event) {
              _vm.copyText(_vm.ftbStatus(props.row));
            }
          }
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.ftbStatus(props.row))
          }
        })])], 1)])], 1)], 1)]) : props.column.field === 'ftb.action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": _vm.ftbStatus(props.row) !== 'KAPAL BERANGKAT' ? _vm.ftbStatus(props.row) === 'KEGIATAN DIBATALKAN' ? 'outline-danger' : 'outline-warning' : 'outline-success',
            "block": "",
            "to": {
              name: 'dashboard-monitoring-rrv-tug-boat-view',
              params: {
                id: props.row.id,
                api: 'form_tug_boat'
              }
            },
            "size": "lg"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": 'PenToolIcon',
            "size": "25"
          }
        }), _vm._v(" Realisasikan ")], 1)], 1), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "outline-primary",
            "block": "",
            "size": "lg",
            "to": {
              name: 'dashboard-monitoring-rrv-tug-boat-summary',
              params: {
                id: props.row.id,
                api: 'form_chain_tbbm'
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ActivityIcon",
            "size": "25"
          }
        }), _vm._v(" Summary ")], 1)], 1) : props.column.field === 'sedang_digunakan' ? _c('span', [props.row.sedang_digunakan ? _c('strong', [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.sedang_digunakan);
            }
          }
        }, [_vm._v(_vm._s(props.row.sedang_digunakan))])]) : _c('strong', [_vm._v("-")]), _c('hr'), props.row.sedang_digunakan ? _c('span', [_vm._v(" Last Used By ")]) : _c('span', [_vm._v("-")]), props.row.sedang_digunakan ? _c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.copyText("Last Used By ".concat(props.row.pemilik));
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.pemilik) + " ")])]) : _vm._e()], 1) : props.column.field === 'currently' ? _c('span', [props.row.last_used_by_ftb ? _c('strong', [_vm._v(" Form Tug Boat: "), _c('b-badge', {
          staticClass: "cursor-pointer",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.detailTugBoat(props.row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        }), _vm._v(" Lihat data ")], 1)], 1) : _c('strong', [_vm._v("-")]), _c('hr'), props.row.last_used_by_model_id ? _c('strong', [_vm._v(" " + _vm._s(props.row.last_used_by_model === 'form_hulu_migas_id' ? 'Form Hulu Migas' : 'Form TBBM') + ": "), _c('b-badge', {
          staticClass: "cursor-pointer",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.detailFormChain(props.row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        }), _vm._v(" Lihat data ")], 1)], 1) : _c('strong', [_vm._v("-")])]) : props.column.field === 'pip_name_barang' ? _c('span', [_c('strong', [_c('span', [_vm._v(_vm._s(props.row.pip_name_barang))]), _c('br'), _c('span', [_vm._v("Volume: " + _vm._s(props.row.pip_volume) + " " + _vm._s(props.row.pip_satuan))])])]) : props.column.field === 'name_tug_boat' ? _c('span', [_c('strong', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.name_tug_boat);
            }
          }
        }, [_vm._v(_vm._s(props.row.name_tug_boat))]), _vm._v(" - "), _c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.copyText(props.row.no_pkk_tug_boat);
            }
          }
        }, [_vm._v(_vm._s(props.row.no_pkk_tug_boat))]), _c('hr'), _c('h5', [_vm._v(" First Created BY "), _c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.copyText("Created BY ".concat(props.row.source));
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.source) + " ")])])], 1)])]) : props.column.field === 'action' ? _c('span', [_c('span', [_vm.editPermission(props.row) ? _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "30"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "primary",
            "toggle-class": "p-0",
            "no-caret": ""
          },
          on: {
            "click": function click($event) {
              return _vm.methodModalTugBoat(true, null, 'is_edit', props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50 cursor-pointer",
          attrs: {
            "icon": "PenToolIcon"
          }
        }), _c('span', [_vm._v("Ubah data tug boat")])], 1)], 1) : _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                staticStyle: {
                  "cursor": "not-allowed"
                },
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "30"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          staticStyle: {
            "cursor": "not-allowed"
          },
          attrs: {
            "variant": "danger",
            "toggle-class": "p-0",
            "no-caret": ""
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          staticStyle: {
            "cursor": "not-allowed"
          },
          attrs: {
            "icon": "PenToolIcon"
          }
        }), _c('span', {
          staticStyle: {
            "cursor": "not-allowed"
          }
        }, [_vm._v("Sedang digunakan oleh tenant lain")])], 1)], 1)], 1)]) : _c('span', [_c('strong', [_vm._v(_vm._s(props.formattedRow[props.column.field]))])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }