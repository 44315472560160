<template>
  <div class="monitoring">
    <FormModal
      :key="`ModalTugBoat${keyModalTugBoat}`"
      uri="/tug_boat"
      title="Data Master Tug Boat"
      :is-modal-active.sync="dataModalTugBoat"
      :payload="dataPayloadModalTugBoat"
      :column="columnModalTugBoat"
      :action="operationModalTugBoat"
      :data-i-d="`${dataIDModalTugBoat}`"
      :resolve-fetch-detail="methodModalTugBoatResolveFetchDetail"
      @closeModal="methodModalTugBoat"
    />
    <b-row>
      <b-col md="8" cols="12">
        <b-form-group>
          <div class="d-flex align-items-center">
            <!--  -->
          </div>
        </b-form-group>
      </b-col>
      <b-col class="text-right mb-2" md="4" cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-button
            v-if="$store.state.app.roleName !== 'viewer' && !disableCreateData"
            id="tambah-data"
            class="button-tambah mr-1"
            :variant="'success'"
          >
            <!-- @click="newData(status)" -->
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Tambah Data
              </strong>
            </span>
          </b-button>
          <!-- <b-button
            id="tambah-data"
            class="button-tambah mr-1"
            :variant="'warning'"
          >
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Export to Excel (
                {{
                  selectedRows.length === 0
                    ? 'ALL Rows'
                    : `Only Row Selected (${selectedRows.length})`
                }}
                )
              </strong></span
            >
          </b-button> -->
        </div>
      </b-col>
    </b-row>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <!-- table -->
    <vue-good-table
      :key="yoRender"
      class="yo-good-table"
      mode="remote"
      :is-loading="yo_isLoading"
      :total-rows="total_records"
      :columns="
        $store.state.app.roleName === 'viewer' ||
        $store.state.app.roleName === 'jetty man'
          ? columns.filter((item) => item.field !== 'action')
          : columns
      "
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder:
          'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      }"
      @on-search="onSearchTable"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`
      }"
      :group-options="{
        enabled: thereIsGroup
      }"
      compact-mode
      theme="polar-bear"
      style-class="vgt-table bordered table-hover"
      :line-numbers="true"
      :fixed-header="false"
      max-height="700px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <!--
         -->
      <template slot="table-header-row" slot-scope="props">
        Kegiatan
        <BBadge variant="primary" class="mr-1">
          <strong>{{ props.row.label }}</strong>
        </BBadge>
        <BBadge variant="secondary">
          Jumlah Data : {{ props.row.children.length }}
        </BBadge>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->
        <span v-if="props.column.field === 'pip_no_pbmbb'">
          <strong>
            {{ props.row.pip_no_pbmbb }}
            <br />
            <span
              v-text="
                `${
                  props.row.kegiatan_hm.keg_name === 'BONGKAR'
                    ? 'Asal: '
                    : 'Tujuan: '
                }${props.row.pip_address}`
              "
            ></span>
          </strong>
        </span>
        <!-- RiverRouteFormTugBoatTable custom_table -->
        <span v-else-if="props.column.field === 'ftb.kapal'">
          <strong>
            <span
              class="cursor-pointer"
              @click="copyText(props.row.tug_boat.name_tug_boat)"
            >
              {{ props.row.tug_boat.name_tug_boat }}
            </span>
            <div class="divider">
              <div class="divider-text">Nomor PKK</div>
            </div>
            <span>
              <b-badge
                href="#"
                :variant="'light-primary'"
                @click="copyText(props.row.tug_boat.no_pkk_tug_boat)"
                class="d-block"
              >
                {{ props.row.tug_boat.no_pkk_tug_boat }}
              </b-badge>
            </span>
            <hr />
          </strong>
          First Created BY
          <b-badge variant="light-primary">{{ props.row.source }}</b-badge>
        </span>
        <span v-else-if="props.column.field === 'ftb.keterangan'">
          <strong>
            <span>&nbsp;</span>
            <div class="divider">
              <div class="divider-text">Rencana Sandar</div>
            </div>
            <b-badge
              href="#"
              :variant="'light-primary'"
              @click="copyText(dateHumanReadAble(props.row.rencana_sandar))"
              class="d-block"
            >
              <feather-icon icon="CalendarIcon" class="mr-50" />
              {{ dateHumanReadAble(props.row.rencana_sandar) }}
              <feather-icon
                class="cursor-pointer mr-1"
                :icon="
                  dateHumanReadAble(props.row.rencana_sandar).includes('AM')
                    ? 'SunIcon'
                    : 'MoonIcon'
                "
              />
            </b-badge>
            <hr />
            <h5>
              Direncanakan oleh
              <b-badge variant="light-primary">
                <strong
                  @click="copyText(props.row.user?.abilities[0]?.action)"
                  >{{ props.row.user?.abilities[0]?.action }}</strong
                >
              </b-badge>
            </h5>
          </strong>
        </span>
        <span v-else-if="props.column.field === 'ftb.status'">
          <h6>
            <!-- {{ props.row.id }} -->
            <b-row>
              <b-col>
                <strong>
                  <b-badge
                    pill
                    :variant="formStatusVariant(ftbStatus(props.row))"
                    class="d-block py-1"
                    @click="copyText(ftbStatus(props.row))"
                  >
                    <span v-text="ftbStatus(props.row)" />
                  </b-badge>
                </strong>
              </b-col>
            </b-row>
          </h6>
        </span>
        <span v-else-if="props.column.field === 'ftb.action'">
          <span>
            <b-button
              :variant="
                ftbStatus(props.row) !== 'KAPAL BERANGKAT'
                  ? ftbStatus(props.row) === 'KEGIATAN DIBATALKAN'
                    ? 'outline-danger'
                    : 'outline-warning'
                  : 'outline-success'
              "
              block
              :to="{
                name: 'dashboard-monitoring-rrv-tug-boat-view',
                params: {
                  id: props.row.id,
                  api: 'form_tug_boat'
                }
              }"
              size="lg"
            >
              <feather-icon
                class="cursor-pointer"
                :icon="'PenToolIcon'"
                size="25"
              />
              Realisasikan
            </b-button>
          </span>
          <b-button
            class="mt-1"
            variant="outline-primary"
            block
            size="lg"
            :to="{
              name: 'dashboard-monitoring-rrv-tug-boat-summary',
              params: {
                id: props.row.id,
                api: 'form_chain_tbbm'
              }
            }"
          >
            <feather-icon
              class="cursor-pointer"
              icon="ActivityIcon"
              size="25"
            />
            Summary
          </b-button>
        </span>
        <!-- RiverRouteTugBoatTable custom_table -->
        <!-- sedang_digunakan -->
        <span v-else-if="props.column.field === 'sedang_digunakan'">
          <strong v-if="props.row.sedang_digunakan">
            <span @click="copyText(props.row.sedang_digunakan)">{{
              props.row.sedang_digunakan
            }}</span>
          </strong>
          <strong v-else>-</strong>
          <hr />
          <!-- <strong>
            Updated AT
            <span>{{ dateHumanReadAble(props.row.updated_at) }}</span>
          </strong> -->
          <!-- <hr /> -->
          <span v-if="props.row.sedang_digunakan"> Last Used By </span>
          <span v-else>-</span>
          <b-badge variant="light-primary" v-if="props.row.sedang_digunakan">
            <span @click="copyText(`Last Used By ${props.row.pemilik}`)">
              {{ props.row.pemilik }}
            </span>
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'currently'">
          <strong v-if="props.row.last_used_by_ftb">
            Form Tug Boat:
            <b-badge
              class="cursor-pointer"
              variant="primary"
              @click="detailTugBoat(props.row)"
            >
              <feather-icon icon="EyeIcon" /> Lihat data
            </b-badge>
          </strong>
          <strong v-else>-</strong>
          <hr />
          <strong v-if="props.row.last_used_by_model_id">
            {{
              props.row.last_used_by_model === 'form_hulu_migas_id'
                ? 'Form Hulu Migas'
                : 'Form TBBM'
            }}:
            <b-badge
              class="cursor-pointer"
              variant="primary"
              @click="detailFormChain(props.row)"
            >
              <feather-icon icon="EyeIcon" /> Lihat data
            </b-badge>
          </strong>
          <strong v-else>-</strong>
        </span>
        <!-- end of RiverRouteTugBoatTable custom_table -->
        <span v-else-if="props.column.field === 'pip_name_barang'">
          <strong>
            <span>{{ props.row.pip_name_barang }}</span>
            <br />
            <span
              >Volume: {{ props.row.pip_volume }}
              {{ props.row.pip_satuan }}</span
            >
          </strong>
          <!-- {{ dateHumanReadAble(props.row.rencana_sandar) }}
          <feather-icon
            class="cursor-pointer mr-1"
            :icon="
              dateHumanReadAble(props.row.rencana_sandar).includes('AM')
                ? 'SunIcon'
                : 'MoonIcon'
            "
          /> -->
        </span>
        <span v-else-if="props.column.field === 'name_tug_boat'">
          <strong>
            <span
              class="cursor-pointer"
              @click="copyText(props.row.name_tug_boat)"
              >{{ props.row.name_tug_boat }}</span
            >
            -
            <span
              class="cursor-pointer"
              @click="copyText(props.row.no_pkk_tug_boat)"
              >{{ props.row.no_pkk_tug_boat }}</span
            >

            <hr />
            <h5>
              First Created BY
              <b-badge variant="light-primary">
                <span @click="copyText(`Created BY ${props.row.source}`)">
                  {{ props.row.source }}
                </span>
              </b-badge>
            </h5>
          </strong>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              v-if="editPermission(props.row)"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="30"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                variant="primary"
                toggle-class="p-0"
                no-caret
                @click="methodModalTugBoat(true, null, 'is_edit', props.row.id)"
              >
                <feather-icon class="mr-50 cursor-pointer" icon="PenToolIcon" />
                <span>Ubah data tug boat</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item variant="danger" toggle-class="p-0" no-caret>
                <feather-icon class="mr-50 cursor-pointer" icon="TrashIcon" />
                <span>Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
            <b-dropdown
              v-else
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="30"
                  style="cursor: not-allowed"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                variant="danger"
                toggle-class="p-0"
                no-caret
                style="cursor: not-allowed"
              >
                <feather-icon
                  class="mr-50"
                  icon="PenToolIcon"
                  style="cursor: not-allowed"
                />
                <span style="cursor: not-allowed"
                  >Sedang digunakan oleh tenant lain</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <strong>{{ props.formattedRow[props.column.field] }}</strong>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'
import FormModal from '@views/pages/components/modal/FormModal.vue'

export default {
  name: 'CompGoodTable',
  components: {
    FormModal,
    VSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr
  },
  props: {
    resolveFetchResponse: Function,
    resolveFetchPayload: Function,
    dateHumanReadAble: Function,
    columns: Array,
    thereIsGroup: Boolean,
    disableCreateData: Boolean
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotification } = useAppConfig()
    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      anIncomingNotification /* FirebaseFCM */
    }
  },
  data() {
    return {
      // FORM MODAL
      keyModalTugBoat: 1,
      dataModalTugBoat: false,
      dataPayloadModalTugBoat: {
        /* ID_DATA */
        no_pkk_tug_boat: null,

        name_tug_Boat: null
      },
      columnModalTugBoat: [
        {
          label: 'Nomor PKK TUG Boat',
          vModel: 'no_pkk_tug_boat',
          config: {
            md: 6
          },
          input: {
            type: 'form-input'
          }
        },
        {
          label: 'Nama TUG Boat',
          vModel: 'name_tug_boat',
          config: {
            md: 6
          },
          input: {
            type: 'form-input'
          }
        }
      ],
      operationModalTugBoat: null,
      dataIDModalTugBoat: null,

      yoRender: 1,
      /* PROGRESS | RENDERING | REFS */
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      /* PROGRESS | RENDERING | REFS */

      /* GOOD TABLE DATA() DONT MODIFIED */
      onFiltering: false,
      total_records: 0,
      rows: [],
      selectedRows: [],
      serverParams: {
        columnFilters: {},
        searchTerm: '',
        sort: {
          field: 'created_at',
          type: 'DESC'
        },
        page: 1,
        perPage: 10
      }
    }
  },
  computed: {
    formStatusVariant() {
      return (any) => this.$variantColumnFormStatus[any]
    },
    editPermission() {
      return (row) => {
        if (row.last_used_by_model === 'form_hulu_migas_id') {
          return this.$can('Hulu Migas', '')
        } else if (row.last_used_by_model === 'form_tbbm_id') {
          return this.$can('TBBM', '')
        } else {
          return true
        }
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    isLoading: {
      get() {
        return this.yo_isLoading
      },
      set(val) {
        this.yo_isLoading = val
      }
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotification() {
      this.fetchData(true, '')
      console.log(
        'RMSungai.vue : an incoming mesage key : ',
        this.anIncomingNotification
      )
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
    }
  },
  mounted() {
    // document.body.style.zoom = '100%'
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.fetchData(true, '')
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    /* START FROM HERE DONT MODIFIED */
    moment,
    fieldCustom() {
      // if ($store.state.app.roleName === 'viewer') {
      //   return this.columns
      // }
      return this.columns
    },
    ftbStatus(row) {
      const end = row.tug_sandar_end_time
      const start = row.tug_sandar_start_time
      if (row.is_cancel === true) {
        return 'KEGIATAN DIBATALKAN'
      } else {
        if (start && end) {
          return 'KAPAL BERANGKAT'
        }
        if (start) {
          return 'KAPAL SANDAR'
        }
      }
      return 'KAPAL RENCANA SANDAR'
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    methodModalTugBoatResolveFetchDetail(response) {
      return response.data.tug_boat
    },
    methodModalTugBoat(
      val = false,
      callBack = null,
      operation = null,
      tug_boat_id = null
    ) {
      if (this.disableCrudX) {
        this.$alert({
          title: `Action was disabled`,
          text: `tidak bisa melakukan aksi, karena form status 'KAPAL BERANGKAT'`,
          variant: 'warning',
          icon: 'XCircleIcon'
        })
        return
      }
      if (operation) {
        this.operationModalTugBoat = operation
        this.dataIDModalTugBoat = tug_boat_id
      }
      this.dataModalTugBoat = val

      if (callBack) {
        console.log('callBack: ', callBack)
        if (callBack.includes('berhasil')) {
          this.$alert({
            title: `Rencana Kegiatan`,
            text: `${callBack}`,
            variant: 'success',
            icon: 'CheckCircleIcon'
          })
          this.fetchData(true, '')
        } else {
          this.$alert({
            title: `Rencana Kegiatan`,
            text: `Tidak berhasil membuat rencana kegiatan`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      }
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.isLoading = false
    },
    isTimerStart() {
      this.isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    // dateHumanReadAble(value) {
    //   const format = moment(value).format('D MMMM - hh:mm A')
    //   if (format === 'Invalid date') {
    //     return 'Belum Diisi ❗️'
    //   }
    //   return format
    // },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    selectionChanged(params) {
      console.log('selectionChanged: ', params)
      this.selectedRows = params.selectedRows
    },
    onPageChange(params) {
      console.log('onPageChange: ', params)
      // this.updateParams({ page: params })
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, '')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      // this.updateParams({ perPage: params })
      this.updateParams({ perPage: params.currentPerPage })
      this.fetchData(true, '')
    },

    onSortChange(params) {
      console.log(params)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, '')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, '')
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        this.updateParams({ page: 1 })
        console.log('search: ', params.searchTerm)
        this.fetchData(true, '')
      }, 300)
    },
    /* END FROM HERE DONT MODIFIED */

    /* FETCH DATA | AXIOS */
    fetchData({ state = null, keyword = null }) {
      console.log('fetch data')
      this.isTimerStart()

      const payload = this.resolveFetchPayload()

      const uri = payload.API_SAL
      const originalServerParams = payload.serverParams

      this.serverParams = {
        ...this.serverParams,
        ...originalServerParams
      }

      useJwt.http
        .get(`${uri}`, {
          params: {
            ...this.serverParams // included query searchTerms
          }
        })
        .then((res) => {
          const { total_records } = res.data
          // eslint-disable-next-line camelcase
          this.total_records = total_records
          // eslint-disable-next-line camelcase
          const get_rows = this.resolveFetchResponse(res).map((data) => ({
            ...data
          }))
          this.rows = get_rows
          this.isTimerDone()
          // this.yoRender += 1
        })
    },
    detailTugBoat(row) {
      const dataID = row.last_used_by_ftb
      const isDiTarik = row.last_used_by_model // isChainToBoat
      if (isDiTarik) {
        const form =
          row.last_used_by_model === 'form_hulu_migas_id'
            ? 'Form Hulu Migas'
            : 'Form TBBM'
        this.$swal({
          position: 'top-end',
          icon: 'info',
          title: `Tug Boat sedang menarik boat utama, directly Open ${form} (Main Data). ~ Automatic close in 4500 seconds ~`,
          showConfirmButton: false,
          timer: 4500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        this.detailFormChain(row)
      } else {
        this.$router.push({
          name: 'dashboard-monitoring-rrv-tug-boat-view',
          params: {
            id: dataID,
            api: 'form_tug_boat'
          }
        })
      }
    },
    detailFormChain(row) {
      const isTBBM =
        row.last_used_by_model === 'form_hulu_migas_id' ? false : true
      const dataID = row.form_chain_id
      if (isTBBM) {
        /* isTBBM */
        this.$router.push({
          name: 'dashboard-monitoring-rrv-tbbm-view',
          params: {
            id: dataID,
            api: 'form_chain_tbbm'
          }
        })
      } else {
        /* isHulu Migas */
        this.$router.push({
          name: 'dashboard-monitoring-rrv-hulu-migas-view',
          params: {
            id: dataID,
            api: 'form_chain_hm'
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;
            .vgt-global-search__input {
              border: none !important;
              .input__icon {
                font-weight: 700 !important;
              }
              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
